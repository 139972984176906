import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment'
import _ from 'lodash'
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Button } from '@mui/material';

import AppContext from '../../context/app'
import AnswerTally from '../../components/AnswerTally';
import threatAssessmentService from '../../services/threatAssessment';


export default function Report(props) {
  const context = useContext(AppContext)
  const { threatAssessmentId } = useParams()
  const [threatAssessment, setThreatAssessment] = useState()
  const [threatAssessmentQuestions, setThreatAssessmentQuestions] = useState([])

  useEffect( async () => {
    window.scroll(0,0)

    try {
      let loadedThreatAssessment = await new threatAssessmentService().get(threatAssessmentId)
      if (loadedThreatAssessment && loadedThreatAssessment.error)
        return
      
      setThreatAssessment(loadedThreatAssessment)
      
      //get unique set of questions for the Answer Tallys
      let uniqueThreatAssessmentQuestions = _.uniqBy(_.flatten(_.flatten(loadedThreatAssessment.interviewers.map( (interviewer) => { return interviewer.questionSections })).map( section => section.questions )),  (question) => question.threatAssessmentInterviewQuestionId )      
      setThreatAssessmentQuestions(uniqueThreatAssessmentQuestions)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }

  },[])

  let ratingColorMapping = { 'Lower Risk': 'green' , 'Higher Risk': 'orange', 'Extremely High Risk': 'red', 'High Risk': 'orange'}
  let threatBarWidth = '0%'; 
  if (threatAssessment)
    threatBarWidth = `${threatAssessment.threatLevelPoints * 1.5}%`;

  if (threatAssessment && !threatAssessment.interviewers.length)
    return (<Paper className="paper">Please complete interviews</Paper>)

  return (
    <div id='threatAssessmentReport'>
              <div>
                { threatAssessment && threatAssessmentQuestions ? 
                  <div>
                        <Paper>
                          <h2>Incident or Behavior of Concern</h2>
                          {['Threat Assessment','Suicide/Self Harm Assessment','Employee Assessment'].indexOf(threatAssessment.typeName) > -1 ? 
                            <div>
                              { !threatAssessment.resolutionClassification ?
                                <div>
                                  {  threatAssessment.interviewers.find( i => ['threatAssessment','employeeAssessment','selfHarmAssessment'].includes(i.questionType) ) ?
                                  <div className="threat-bar-holder">
                                      <div className="threat-bar" style={{width : threatBarWidth, 'backgroundColor': ratingColorMapping[threatAssessment.threatLevelClassification] }} >
                                        <span className="rating-label"><strong>Risk Level:</strong> {threatAssessment.threatLevelClassification}</span>
                                      </div>
                                  </div>
                                  :
                                    <div className="threat-bar-more-information">
                                      Not enough information gathered to determine threat level.
                                    </div>
                                  }
                                </div>
                              :
                                <div >
                                  <div className="resolutionClassification">
                                    <label>Classification:</label> {threatAssessment.resolutionClassification}
                                  </div>
                                </div>
                              }                              
                            </div>
                          : null }
                        
                          { threatAssessment.resolution?.resolutionNotes ? 
                            <div>
                              {threatAssessment.resolution.resolutionNotes}
                            </div>
                          : null }

                          <div>
                            <label>Brought to our attention:</label>
                              {threatAssessment.reason} 
                              { threatAssessment.comments && threatAssessment.comments.length ? 
                                <span> - {threatAssessment.comments}</span>
                              : null }
                          </div>
                        </Paper>

                        { threatAssessment.interviewers.length ? 
                          <div>
                            { threatAssessment.interviewers.map( (interviewer, index) => {
                                return(
                                  <Paper className="interview flex-row" key={index}>
                                    <div className="interview-header flex-row"> 
                                      Interview {index+1}: { interviewer.config.interviewText } with {interviewer.intervieweeFirstName} {interviewer.intervieweeLastName}
                                      <span><Link to={`/interview/edit/${interviewer.threatAssessmentInterviewerId}`}><Icon className="fa fa-edit"/></Link></span>
                                    </div>
                                    <div className="interviewQuestion-subTitle flex-row">
                                      Interviewer: {interviewer.firstName} {interviewer.lastName} by {interviewer.methodOfInterview} { interviewer.methodOfInterviewLocation ? <span>&nbsp;({interviewer.methodOfInterviewLocation})&nbsp;</span> : null } on {moment.utc(interviewer.createDate).local().format('MM/DD/YYYY hh:mm a')}
                                    </div>
                                    
                                    { interviewer.config.subTitle ?
                                      <div className="interviewQuestion-subText flex-row" dangerouslySetInnerHTML={{ __html: interviewer.config.subTitle }} ></div>
                                    : null }

                                    { interviewer.questionSections.map( (questionSection, index) => {
                                      return (
                                        <div key={index}>
                                          { questionSection.section ?
                                            <div>
                                              <h3>{questionSection.section}</h3>
                                              <div className="interviewQuestion-subText">
                                                {questionSection.sectionSubText}
                                              </div>
                                            </div>
                                          : null }

                                          <ol>
                                            { questionSection.questions.map( (question, index) => {
                                                return(
                                                  <li className={`question flex-row `} key={index}>
                                                    <div className="questionText flex-row">Q{index+1}: {question.text}</div>
                                                    { question.answers?.length ? 
                                                      <div>
                                                        { question.answerType === 'radio' ?
                                                          <div>
                                                            { question.answers.map( (answer, index) => {
                                                              return (
                                                                <span key={index}>
                                                                  <span className="answer" >{ answer.text } </span>
                                                                  { answer.isEdited ? <span> (Edited)</span> : null }
                                                                </span>
                                                              )
                                                            })}
                                                          </div>
                                                        : null }

                                                        { question.answerType === 'checkBox' ?
                                                          <div>
                                                            { question.answers.map( (answer, index) => {
                                                              return (
                                                                <div key={index}>
                                                                  <span className="answer" >{ answer.text } </span>
                                                                  { answer.isEdited ? <span> (Edited)</span> : null }
                                                                </div>
                                                              )
                                                            })}
                                                          </div>
                                                        : null }

                                                      </div>
                                                    : null }

                                                    <div>
                                                    { question.comments && question.comments.comment &&  question.comments.comment.length ?
                                                      <span>
                                                        { question.answers.length ? <span>&nbsp;-&nbsp;</span> : null }
                                                        {question.comments.comment}
                                                        { question.comments.isEdited ? <span> (Edited)</span> : null }
                                                      </span>
                                                      : null
                                                    }
                                                    </div>
                                                  </li>
                                                )
                                              })
                                            }
                                          </ol>
                                          <div className="interviewQuestion-footerText">{interviewer.config.footerText}</div>
                                        </div>
                                      )
                                    })}
                                  </Paper>
                                )
                              })}
                          </div>
                        : null }

                        { threatAssessment.resolveQuestions?.find( ta => ta.type === 'summaryOfObservations' )?.sections.flatMap( s => s.questions ).find( q => !!q.answer ) ? 
                          <div>
                              <Paper>
                                <h2>Summary Of Observations</h2>

                                  <ol>
                                    { threatAssessment.resolveQuestions.find( ta => ta.type === 'summaryOfObservations' ).sections.map( (section, index) => {
                                      return (
                                        <div key={index}>
                                          { section.questions.map( (question, index) => {
                                            return(
                                              <li className={`question flex-row `} key={index}>
                                                <div className="questionText flex-row">Q{index+1}: {question.text}</div>
                                                <div>
                                                { question.answer ? 
                                                  <span>
                                                    <span className="answer" >{ question.answer.text } </span>
                                                    { question.comments && question.comments.comment &&  question.comments.comment.length ?
                                                      <span>
                                                        { question.answers?.length ? <span>&nbsp;-&nbsp;</span> : null }
                                                        {question.comments.comment}
                                                      </span>
                                                    : null }

                                                  </span>
                                                : 
                                                  <span>NA</span>
                                                }
                                                </div>
                                              </li>
                                            )
                                          })
                                          }
                                      </div>
                                      )
                                      })
                                    }
                                  </ol>
                              </Paper>
                          </div>
                        : null }

                         { threatAssessmentQuestions.find( ta => ta.questionType === 'witness' || ta.questionType === 'target' ) ?
                          <Paper>
                              <h2>Additional Notes</h2>
                            { threatAssessmentQuestions.find( ta => ta.questionType === 'target' ) ?
                              <div className="threat-answer-tally">
                                <AnswerTally threatAssessmentQuestions={threatAssessmentQuestions.filter( ta => ta.questionType === 'target' )} threatAssessment={threatAssessment} commentsOnly={true} yesOnly={true} ></AnswerTally>
                              </div>
                            : null }
                            { threatAssessmentQuestions.filter( ta => ta.questionType === 'witness' ) ?
                              <div className="threat-answer-tally">
                                <AnswerTally threatAssessmentQuestions={threatAssessmentQuestions.filter( ta => ta.questionType === 'witness' )} threatAssessment={threatAssessment} commentsOnly={true} yesOnly={true} ></AnswerTally>
                              </div>
                            : null }
                          </Paper>
                        : null }
                        
                        { [1,3,4].indexOf(threatAssessment.threatAssessmentTypeId) > -1 ?
                        <Paper>
                          <h2>Collective Analysis Findings</h2>
                          <div>
                            {[1,4].indexOf(threatAssessment.threatAssessmentTypeId) > -1 ? 
                            <div>
                              { !threatAssessment.resolutionClassification ?
                                <div className="threat-bar-holder">
                                    <div className="threat-bar" style={{width : threatBarWidth, 'backgroundColor': ratingColorMapping[threatAssessment.threatLevelClassification] }} >
                                      <span className="rating-label"><strong>Threat Level:</strong> {threatAssessment.threatLevelClassification}</span>
                                    </div>
                                </div>
                              :
                                null
                              }
                            </div>
                          : null }

                           { threatAssessmentQuestions.find( ta => ta.questionType === 'threatAssessment' ) ?
                            <div className="threat-answer-tally no-header">
                              <AnswerTally threatAssessmentQuestions={threatAssessmentQuestions.filter( ta => ta.questionType === 'threatAssessment' )} threatAssessment={threatAssessment} commentsOnly={true} yesOnly={true} ></AnswerTally>
                            </div>
                          : null }

                          { threatAssessmentQuestions.find( ta => ta.questionType === 'employeeAssessment' ) ?
                            <div className="threat-answer-tally">
                              <AnswerTally threatAssessmentQuestions={threatAssessmentQuestions.filter( ta => ta.questionType === 'employeeAssessment' )} threatAssessment={threatAssessment} commentsOnly={true} yesOnly={true} ></AnswerTally>
                            </div>
                          : null }

                          { threatAssessmentQuestions.find( ta => ta.questionType === 'selfHarmAssessment' ) ?
                              <div>
                                <div className="answer-tally-header">Suicide Screening</div>
                                <AnswerTally threatAssessmentQuestions={threatAssessmentQuestions.filter( ta => ta.questionType === 'selfHarmAssessment' )} threatAssessment={threatAssessment} yesOnly={true} ></AnswerTally>
                              </div>
                            : null }
                          </div>
                        </Paper>
                        : null }

                        { threatAssessment.resolveQuestions?.find( ta => ta.type === 'observationsForIntervention' )?.sections.flatMap( s => s.questions ).find( q => !!q.answer ) ? 
                          <div>
                              <Paper>
                                <h2>Observations Suggesting Need for Intervention</h2>
                                  <ol>
                                    { threatAssessment.resolveQuestions.find( ta => ta.type === 'observationsForIntervention' ).sections[0].questions.map( (question, index) => {
                                        return(
                                          <li className={`question flex-row `} key={index}>
                                            <div className="questionText flex-row">Q{index+1}: {question.text}</div>
                                            <div>
                                            { question.answer ? 
                                              <span>
                                                <span className="answer" >{ question.answer.text } </span>
                                              </span>
                                            : 
                                              null
                                            }
                                            </div>

                                            { question.comments && question.comments.comment &&  question.comments.comment.length ?
                                                <span>
                                                  { question.answer?.text ? <span>&nbsp;-&nbsp;</span> : null }
                                                  {question.comments.comment}
                                                </span>
                                              : null }
                                          </li>
                                        )
                                      })
                                    }
                                  </ol>
                              </Paper>
                          </div>
                        : null }

                        { threatAssessment.resolveQuestions?.find( ta => ta.type === 'behaviorInterventionPlan' )?.sections.flatMap( s => s.questions ).find( q => !!q.answer ) ? 
                          <div>
                              <Paper>
                                <h2>Behavior Intervention Plan</h2>
                                  <ol>
                                    { threatAssessment.resolveQuestions.find( ta => ta.type === 'behaviorInterventionPlan' ).sections.map( (section, index) => {
                                      return (
                                        <div>
                                          <h3>{section.section}</h3>
                                          { section.questions.map( (question, index) => {
                                              return(
                                              <li className={`question flex-row `} key={index}>
                                                <div className="questionText flex-row">Q{index+1}: {question.text}</div>
                                                <div>
                                                { question.answer ? 
                                                  <span>
                                                    <span className="answer" >{ question.answer.text } </span>
                                                  </span>
                                                : 
                                                  null
                                                }

                                                { question.comments && question.comments.comment &&  question.comments.comment.length ?
                                                    <span>
                                                      { question.answers?.length ? <span>&nbsp;-&nbsp;</span> : null }
                                                      {question.comments.comment}
                                                    </span>
                                                  : null }

                                                </div>
                                              </li>
                                            )
                                          })}
                                        </div>
                                      )
                                    })}                                   
                                  </ol>
                              </Paper>
                          </div>
                        : null } 

                        { threatAssessment.behaviorPlanParticipants.length ? 
                          <div>
                              <Paper>
                                <h2>Participants in Plan Development</h2>

                                  <ol>
                                    { threatAssessment.behaviorPlanParticipants.map( (participant, index) => {
                                        return(
                                          <li className={`participant flex-row `} key={index}>
                                            <div className="action flex-row">{participant.participant} - {participant.comment} </div>
                                          </li>
                                        )
                                      })
                                    }
                                  </ol>
                              </Paper>
                          </div>
                        : null }



                        { threatAssessment.actionPlan && threatAssessment.actionPlan.length ? 
                          <div>
                              <Paper>
                                <h2>Action Plan</h2>
                                  <div className="section">
                                    {threatAssessment.resolution?.resolutionNotes}
                                  </div>
                                  <ol>
                                    { threatAssessment.actionPlan.map( (plan, index) => {
                                        return(
                                          <li className={`plan flex-row `} key={index}>
                                            <div className="action flex-row">{plan.action}</div>
                                          </li>
                                        )
                                      })
                                    }
                                  </ol>
                              </Paper>
                          </div>
                        : null }
                </div>
                :
                    null
                }
                </div>

          </div>
  )
}