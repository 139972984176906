import React, { useState, useEffect, useReducer, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import moment from 'moment'
import { Paper, Icon, Button, Checkbox, TextField, FormControl, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers';

import HelpToolTip from '../../../components/HelpToolTip';
import AppContext from '../../../context/app'
import threatAssessmentService from '../../../services/threatAssessment';
import formService from '../../../services/assessment/form/form';

const formId = 4

export default function Form(props) {
    const context = useContext(AppContext)
    const [loading, setLoading] = useState(true)
    const [assessment, setAssessment] = useReducer((state, newState) => ({ ...state, ...newState }), {});
    const [form, setForm] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { threatAssessmentId: props.threatAssessmentId, formId, data: { 
            sbtmtConvenedDate: moment()
        } }
    );
    
    useEffect( async () => {       
        let loadedAssessment = await new threatAssessmentService().get(props.threatAssessmentId)
        setAssessment(loadedAssessment)
        if (props.editForm)
            setForm(JSON.parse(JSON.stringify(props.editForm)))
        setLoading(false)
    },[])

    useEffect( () => {
        if (loading) return
        form.classification = form.data.preliminaryClassification || null
        setForm(form)
    },[form.data.preliminaryClassification])


    const handleSave = async (event) => {
        event.preventDefault();
        try {
            if (props.editForm)
                await new formService().update(form)
            else
                await new formService().save(form)
            props.history.push({pathname: `/assessment/${form.threatAssessmentId}` });
            context.toastSuccess('Form Saved')
        }
        catch(err){
            console.log(err)
            context.toastError('Form could not be saved')
            Sentry.captureException(err);
        }
    }


    if (assessment.interviewers && !assessment.interviewers.find(i => i.role === 'subjectOfConcern'))
    return (
        <div id="assessmentForm">
            <Paper className="paper">
                <div className="flex-row">
                    <div className="flex-col">
                        Please complete a Student of Concern Interview
                    </div>
                </div>
            </Paper>
        </div>
    )        

    return (
    <div id="assessmentForm">
        <form onSubmit={handleSave}>
            <Paper className="paper">
                <div className="flex-row">
                    <div className="flex-col">
                        <div className="highlighted-instructions">
                            The SBTMT must meet no later than the next school day from the day the initial report was received by the Chair.
                        </div>
                        <div className="section">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker disableFuture={true} fullWidth renderInput={(props) => <TextField required {...props} />} label="Date SBTMT Convened" value={form.data?.sbtmtConvenedDate ? moment.utc(form.data.sbtmtConvenedDate).local() : null} onChange={(e) => {if (!e) { return }; form.data.sbtmtConvenedDate = e.format(); setForm(form)}}/>
                        </LocalizationProvider>
                        </div>
                    </div>
                </div>

                <div>
                    Preliminary SBTMT Determination of Threat Level:
                </div>
                <div className="flex-row">                
                    <div className="flex-col">
                        <FormControl component="fieldset">
                            <RadioGroup row>
                                <FormControlLabel label="Low Level Threat" control={<Radio required />} checked={ form.data.preliminaryClassification === 'Low Level Threat' } type="radio" required onChange={() => { form.data.preliminaryClassification = 'Low Level Threat'; setForm(form) } }/>
                                <FormControlLabel label="Medium Level Threat" control={<Radio required />} checked={ form.data.preliminaryClassification === 'Medium Level Threat' } type="radio" required onChange={() => { form.data.preliminaryClassification = 'Medium Level Threat'; setForm(form) } }/>
                                <FormControlLabel label="High Level Threat" control={<Radio required />} checked={ form.data.preliminaryClassification === 'High Level Threat' } type="radio" required onChange={() => { form.data.preliminaryClassification = 'High Level Threat'; setForm(form) } }/>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                { form.data.preliminaryClassification === 'Low Level Threat' ?
                    <div className="flex-row">
                        <div className="flex-col">
                            <div>
                                <FormControlLabel control={<Checkbox checked={form.data?.requireInitialSSMP} onChange={(e) => { form.data.requireInitialSSMP = !!e.target.checked; setForm(form)}} />} label="Does the Matter Require an Initial SSMP" />
                                <HelpToolTip title={<div>Not required however, if implemented, the minimum period for a Low level of concern SSMP is 90 days</div> }></HelpToolTip>
                            </div>
                        </div>
                    </div>
                : null }
                { form.data.preliminaryClassification === 'Medium Level Threat' ||  form.data.preliminaryClassification === 'High Level Threat' ?
                    <div className="flex-row">
                        <div className="flex-col">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker disableFuture={true} fullWidth renderInput={(props) => <TextField required {...props} />} label="Date SSMP Implemented" value={form.data?.ssmpImplementedDate ? moment.utc(form.data.ssmpImplementedDate).local() : null} onChange={(e) => {if (!e) { return }; form.data.ssmpImplementedDate = e.format(); setForm(form)}}/>
                            </LocalizationProvider>
                        </div>
                    </div>
                : null }

                { form.data.requireInitialSSMP ?
                <div className="flex-row">
                    <div className="flex-col">
                        <TextField label='If Initial SSMP Implemented on any Level of Concern, State the Components and Requirements of the Initial SSMP:' minRows={4}  inputProps={{ maxLength: 2000}}  required multiline type="text" value={form.data?.initialSSMP || ''} onChange={(e) => { if (!form.data) form.data = {}; form.data.initialSSMP = e.target.value; setForm(form) }} />
                    </div>
                </div>
                : null }
            </Paper>

            <Paper className="paper">
                <div>SBTMT Background Information Assignments: <HelpToolTip title={<div>The threat management team will obtain background information from school records, law enforcement records, and mental health providers, as available, to evaluate more thoroughly the threatening or concerning behavior and determine whether a threat toward others or actionable concerning behavior actually exists</div> }></HelpToolTip></div>
                <div className="flex-row">
                    <div className="flex-col">
                        <TextField label='Obtaining Law Enforcement Background Information Regarding Student Assigned to' maxLength="100" type="text" value={form.data?.backgroundLawEnformentInfoAssignmentName || ''} onChange={(e) => { form.data.backgroundLawEnformentInfoAssignmentName = e.target.value; setForm(form)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="flex-col">
                        <TextField label='Obtaining Relevant School Background Information Regarding Student Assigned to' maxLength="100" type="text" value={form.data?.backgroundSchoolInfoAssignmentName || ''} onChange={(e) => { form.data.backgroundSchoolInfoAssignmentName = e.target.value; setForm(form)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="flex-col">
                        <TextField label='Obtaining Student Mental Health Information Assigned to' maxLength="100" type="text" value={form.data?.backgroundMentalHealthInfoAssignmentName || ''} onChange={(e) => { form.data.backgroundMentalHealthInfoAssignmentName = e.target.value; setForm(form)}}/>
                    </div>
                </div>

                <div className="section">List Information Obtained and Made Part of the File for Consideration by the SBTMT (i.e. Law Enforcement Investigative Reports, Criminal History, School Disciplinary Records, School Mental Health Records, Community Mental Health Records, Information Obtained from the Instructional or Administrative Staff Member Personally Familiar with the Student of Concern)</div>
                <div className="flex-row">
                    <div className="flex-col">
                        <TextField label='Information Obtained and Made Part of the File for Consideration' multiline type="text"  inputProps={{ maxLength: 2000}}  value={form.data?.informationObtained || ''} onChange={(e) => { form.data.informationObtained = e.target.value; setForm(form)}}/>
                    </div>
                </div>  

                <div className="section">List Information Regarding the Totality of Concerning Behaviors, Stressors, and Protective Factors to Determine If and Where the Student is on the Pathway to Violence</div>
                <div className="flex-row">
                    <div className="flex-col">
                        <TextField label='Information Obtained of Concerning Behavior' multiline type="text"  inputProps={{ maxLength: 2000}}  value={form.data?.informationObtainedConcerningBehaviors || ''} onChange={(e) => { form.data.informationObtainedConcerningBehaviors = e.target.value; setForm(form)}}/>
                    </div>
                </div> 
            </Paper>                        

            <Paper className="paper">
                <div>The SBTMT must meet within two school days of its initial meeting.</div>

                <div className="flex-row">
                    <div className="flex-col">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker disablePast={true} fullWidth renderInput={(props) => <TextField required {...props} />} label="SBTMT Meeting Date for Final Determination" value={form.data?.finalMeetingDeterminationDate ? moment.utc(form.data.finalMeetingDeterminationDate).local() : null} onChange={(e) => {if (!e) { return }; form.data.finalMeetingDeterminationDate = e.format(); setForm(form)}}/>
                        </LocalizationProvider>
                    </div>
                </div>               
            </Paper>

            <div>
                <Button variant="outlined" type="submit">
                    <Icon className="fa fa-save" />Save
                </Button>
            </div>
        </form>
    </div>
    )
}