import React, { useState, useEffect, useReducer, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Checkbox, Button, TextField, FormGroup, FormControlLabel } from '@mui/material';

import AppContext from '../../../context/app'
import formService from '../../../services/assessment/form/form';

const formId = 8

const formConfig = {
    targetedViolenceIndicators: [
        { name: 'Enhancers', subtitle: 'Risk Factors', choices: [
            'History of violence',
            'Childhood exposure to violence',
            'Substance abuse/dependence',
            'Personality disorder',
            'Severe mental illness',
            'Suicidal ideation (past or present)',
            'Firearms, explosives, or other weapons (interest, possess or access)',
            'History of stalking, trespassing, threatening or menacing behavior',
            'History of non-compliance',
            'Negative family dynamics',
            'Isolation',
            'Instability',
            'Others are concerned',
            'Contagion effect'
        ]},
        { name: 'Enhancers', subtitle: 'Warning Behaviors', choices: [
            'Fixation behavior',
            'Identification',
            'Novel aggression',
            'Leakage',
            'Implied intent to harm',
            'Directly communicated threat',
            'Intimacy effect',
            'Approach'
        ]},
        { name: 'Signs of Potential Imminence', choices: [
            'Energy burst behavior',
            'End of life planning/risk taking',
            'Last resort behavior',
            'Sudden cessation of medications or other substance use',
            'Sudden withdrawal from pattern of life'
        ]},
        { name: 'Triggers', choices: [
            'Loss of a loved one',
            'Separation in a relationship',
            'Expulsion from school',
            'Loss of employment',
            'Significant financial loss',
            'Loss of shelter',
            'Humiliation'
        ]},
        { name: 'Mitigators', choices: [
            'Pursuit of non-violent, legal, or acceptable resolution methods',
            'Sense of humor',
            'Positive, realistic goals',
            'Supportive family',
            'Healthy social network',
            'Positive coping mechanisms',
            'Access to and acceptance of assistance'
        ]}
    ],
    pathwayBehaviors: [{name: 'Grievance', choices: [
        'Fixation/Obsession',
        'Expressions of injustice',
        'Need for revenge',
        'Overwhelming loss',
        'Desire for recognition or fame',
        'Sense of destiny/mission'
    ]},
    {name: 'Violent Ideation', choices: [
        'Violence is acceptable',
        'Fixation with violence',
        'Discuss with others',
        'Identify with other',
        'assailants',
        'Fascination with weapons',
        'Fixation on anniversaries'
    ]},
    {name: 'Research and Planning', choices: [
        'Attack methods',
        'Target research',
        'Inquiries to past attackers',
        'Inquiries to past incidents',
        'Stalking (surveillance)',
        'Planning attack details'
    ]},
    {name: 'Preparation', choices: [
        'Acquire weapon(s)',
        'Assemble equipment',
        'Practice/train',
        'Arrange transportation',
        'Costume/drastic change in appearance',
        'Final-act behaviors'
    ]},
    {name: 'Breach', choices: [
        'Probing security',
        'Traveling to the target',
        'Entering the site',
        'Canvasing the area',
        'Surreptitious approach',
        'Lethal approach'
    ]}
    ],
    riskAssessmentReferralsChoices: [
        'MC Mental Health',
        'Liberty Resources',
        'Pathways / Pros',
        'Family Counseling',
        'Bridges / CAP',
        'Veteran Services',
        'Probation / Parole',
        'District Attorney',
        'School',
        'DSS',
        'Family',
        'Friend',
        'Other'
    ],
    safetyPlanAndMitigationChoices: [
        'No further action at this time',
        'Watch & wait',
        'Third party monitoring– parent / LG / PO / MH',
        'Third party intervention – AM / AA / Behavior therapy',
        'Direct interview',
        'Setting boundaries/limits',
        'Mental health commitment',
        'Substance use treatment',
        'Administrative actions – suspend / expulsion / termination',
        'Civil action – OP / TERPO',
        'Law enforcement action – search warrant / firearms removed',
        'Electric monitoring',
        'Other'
    ]
}

export default function Form(props) {
    const context = useContext(AppContext)
    const [loading, setLoading] = useState(true)
    const [form, setForm] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { threatAssessmentId: props.threatAssessmentId, formId, data: { } }
    );

    useEffect(async () => {
        if (props.editForm) {
            setForm(JSON.parse(JSON.stringify(props.editForm)))
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        if (loading) return
        form.classification = form.data.updatedClassification || form.data.classification
        setForm(form)
    }, [form.data.classification, form.data.updatedClassification])

    const handleSave = async (event) => {
        event.preventDefault();
        try {
            if (props.editForm)
                await new formService().update(form)
            else
                await new formService().save(form)
            props.history.push({ pathname: `/assessment/${form.threatAssessmentId}` });
            context.toastSuccess('Form Saved')
        }
        catch (err) {
            console.log(err)
            context.toastError('Form could not be saved')
            Sentry.captureException(err);
        }
    }

    const handleChoice = (propertyName, choiceValue, isChecked) => {
        if (!form.data[propertyName])
            form.data[propertyName] = []
        if (isChecked) 
            form.data[propertyName].push(choiceValue); 
        else
            form.data[propertyName] = form.data[propertyName].filter( o => o !== choiceValue )
        setForm({...form})
    }

    const handleChoiceNote = (propertyName, choiceValue, note) => {
        if (isChecked) 
            form.data[propertyName].push(choiceValue); 
        else
            assessment.behaviorPlanParticipants = assessment.behaviorPlanParticipants.filter( bpp => bpp.participant !== e.target.value )
        
        setForm({...form})
     }
 


    return (
        <div id="assessmentForm">
            <form onSubmit={handleSave}>
                <Paper className="paper">
                    <h2>Person Of Concern</h2>
                    <div className="flex-row">
                        <div className="flex-col">
                            <TextField label="Home Address" type="text" maxLength="320" value={form.data?.assessedHomeAddress || ''} required onChange={(e) => { form.data.assessedHomeAddress = e.target.value; setForm(form) }} />
                        </div>

                        <div className="flex-col">
                            <TextField label="Phone" type="text" maxLength="15" value={form.data?.assessedPhone || ''} required onChange={(e) => { form.data.assessedPhone = e.target.value; setForm(form) }} />
                        </div>
                    </div>

                    <div className="flex-row">
                        <div className="flex-col">
                        <TextField label="Occupation" type="text" maxLength="45" value={form.data?.assessedOccupation || ''} required onChange={(e) => { form.data.assessedOccupation = e.target.value; setForm(form) }} />
                        </div>

                        <div className="flex-col">
                        <TextField label="Business" type="text" maxLength="45" value={form.data?.assessedBusiness || ''} required onChange={(e) => { form.data.assessedBusiness = e.target.value; setForm(form) }} />
                        </div>
                    </div>   
                </Paper>

                <Paper className="paper">
                    <h2>Referral Source</h2>
                    <div className="flex-row">
                        <div className="flex-col">
                            <TextField label="Phone" type="text" maxLength="15" value={form.data?.referralPhone || ''} required onChange={(e) => { form.data.referralPhone = e.target.value; setForm(form) }} />
                        </div>
                        <div className="flex-col">
                            <TextField label="Relationship" type="text" maxLength="45" value={form.data?.referralRelationship || ''} required onChange={(e) => { form.data.referralRelationship = e.target.value; setForm(form) }} />
                        </div>
                    </div>
                </Paper>

                <Paper className="paper">
                    <h2>Person familiar with POC (parent, guardian, spouse, etc.)</h2>
                    <div className="flex-row">
                        <div className="flex-col">
                        <TextField label="Name" type="text" maxLength="100" value={form.data?.familiarToName || ''} required onChange={(e) => { form.data.familiarToName = e.target.value; setForm(form) }} />
                        </div>

                        <div className="flex-col">
                        <TextField label="Phone" type="text" maxLength="15" value={form.data?.familiarToPhone || ''} required onChange={(e) => { form.data.familiarToPhone = e.target.value; setForm(form) }} />
                        </div>
                    </div>
                    <div className="flex-row">
                        <div className="flex-col">
                        <TextField label="Address" type="text" maxLength="320" value={form.data?.familiarToAddress || ''} required onChange={(e) => { form.data.familiarToAddress = e.target.value; setForm(form) }} />
                        </div>

                        <div className="flex-col">
                        <TextField label="Relationship" type="text" maxLength="45" value={form.data?.familiarToRelationship || ''} required onChange={(e) => { form.data.familiarToRelationship = e.target.value; setForm(form) }} />
                        </div>
                    </div>
                </Paper>

                <Paper className="paper">
                    <h2>Targeted Violence Indicators</h2>
                    (Check all that apply)
                    <div className='flex-row choice-group'>
                    { formConfig.targetedViolenceIndicators.map( (section, index) => {
                        return(
                            <div className="flex-col section choice-column" key={index}>
                                <label>{section.name}</label>
                                <FormGroup row="true" aria-label="position">
                                    { section.choices.map( (choice, index) => {
                                        return (
                                            <div>
                                            <FormControlLabel key={index}
                                                control={<Checkbox/>}
                                                    label={choice}
                                                    labelPlacement="end"
                                                    checked={form.data.targetedViolenceIndicators?.indexOf(choice) > -1}
                                                    onChange={(e) => { handleChoice('targetedViolenceIndicators',choice, e.target.checked)}}
                                            />
                                            </div>
                                        )
                                    })}
                                </FormGroup>
                            </div>
                        )
                    })}
                    </div>                    
                </Paper>

                <Paper className="paper">
                    <h2>Pathway Behaviors</h2>
                    (Check all that apply)
                    <div className='flex-row choice-group'>
                    { formConfig.pathwayBehaviors.map( (section, index) => {
                        return(
                            <div className="flex-col section choice-column" key={index}>
                                <label>{section.name}</label>
                                <FormGroup row="true" aria-label="position">
                                    { section.choices.map( (choice, index) => {
                                        return (
                                            <div>
                                            <FormControlLabel key={index}
                                                control={<Checkbox/>}
                                                    label={choice}
                                                    labelPlacement="end"
                                                    checked={form.data.pathwayBehaviors?.indexOf(choice) > -1}
                                                    onChange={(e) => { handleChoice('pathwayBehaviors',choice, e.target.checked)}}
                                            />
                                            </div>
                                        )
                                    })}
                                </FormGroup>
                            </div>
                        )
                    })}   
                    </div> 
                </Paper>

                <Paper className="paper">
                    <h2>Risk Assessment</h2>
                    <div className='flex-row choice-group'>
                        <div className="flex-col section choice-column">
                            <label>Referrals Made</label>
                            <FormGroup row="true" aria-label="position">
                                { formConfig.riskAssessmentReferralsChoices.map( (choice, index) => {
                                    return (
                                        <div>
                                        <FormControlLabel key={index}
                                            control={<Checkbox/>}
                                                label={choice}
                                                labelPlacement="end"
                                                checked={form.data.riskAssessmentReferrals?.indexOf(choice) > -1}
                                                onChange={(e) => { handleChoice('riskAssessmentReferrals',choice, e.target.checked)}}
                                        />
                                        </div>
                                    )
                                })}
                            </FormGroup>
                        </div>

                        <div className="flex-col section choice-column">
                            <label>Safety Plan / Mitigation options:</label>

                            <FormGroup row="true" aria-label="position">
                                { formConfig.safetyPlanAndMitigationChoices.map( (choice, index) => {
                                    return (
                                        <div>
                                        <FormControlLabel key={index}
                                            control={<Checkbox/>}
                                                label={choice}
                                                labelPlacement="end"
                                                checked={form.data.safetyPlanAndMitigation?.indexOf(choice) > -1}
                                                onChange={(e) => { handleChoice('safetyPlanAndMitigation',choice, e.target.checked)}}
                                        />
                                        </div>
                                    )
                                })}
                            </FormGroup>
                        </div>
                    </div>
                </Paper>                

                <div>
                    <Button variant="outlined" type="submit">
                        <Icon className="fa fa-save" />Save
                    </Button>
                </div>
            </form>
        </div>
    )
}