import React, { useState, useEffect, useReducer, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Button, TextField, MenuItem, FormControl, RadioGroup, Radio, FormControlLabel } from '@mui/material';

import AppContext from '../../../context/app'
import threatAssessmentService from '../../../services/threatAssessment';
import formService from '../../../services/assessment/form/form';
import HelpToolTip from '../../../components/HelpToolTip';

const formId = 1

export default function Form(props) {
    const context = useContext(AppContext)
    const [loading, setLoading] = useState(true)
    const [assessment, setAssessment] = useReducer((state, newState) => ({ ...state, ...newState }), {});
    const [form, setForm] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { threatAssessmentId: props.threatAssessmentId, formId, data: { } }
    );
    
    useEffect( async () => {       
        let loadedAssessment = await new threatAssessmentService().get(props.threatAssessmentId)
        setAssessment(loadedAssessment)
        if (props.editForm){
            setForm(JSON.parse(JSON.stringify(props.editForm)))
        }
        setLoading(false)
    },[])

    useEffect( () => {
        if (loading) return
        form.data.continue2 = !!form.data.shouldFurtherReview || !!props.editForm?.data.continue2
        form.data.continue3 = form.data.continue2 && form.data.incidentType && form.data.incidentType !== 'Self-Harm'
        setForm(form)
    },[loading, assessment.publishToThreatTeam, form.data.initialClassification, form.data.incidentType, form.data.secondClassification, form.data.shouldFurtherReview, form.data.reviewBySBTMT])

    useEffect( () => {
        if (loading) return
            if (form.data.secondClassification) 
                form.classification = form.data.secondClassification
            else if (form.data.incidentType === 'Self-Harm')
                form.classification = 'Self Harm Only'
            else if (form.data.initialClassification)
                form.classification = form.data.initialClassification
            else
                form.classification = null
        setForm(form)
    },[form.data.initialClassification, form.data.secondClassification, form.data.incidentType])

    useEffect( () => {
        if (loading) return
        //changed and set to specific values
        let type = ((!props.editForm || (!(props.editForm.data.initialClassification === 'Unfounded' || props.editForm.data.initialClassification === 'Low Level Threat'))) && (form.data.initialClassification === 'Unfounded' || form.data.initialClassification === 'Low Level Threat')) ? 'initial' : null
        if (!type)
            type = ((!props.editForm || (props.editForm.data.incidentType !== 'Self-Harm')) && (form.data.incidentType && form.data.incidentType === 'Self-Harm')) ? 'selfHarm' : null
        if (!type)
            type = ((!props.editForm || (props.editForm.data.secondClassification !== 'Low Level Threat')) && form.data.secondClassification === 'Low Level Threat') ? 'second' : null 
        form.sendForReviewType = type 
        setForm(form)
    },[form.data.initialClassification, form.data.incidentType, form.data.secondClassification, form.classification])

    useEffect( () => {
        if (loading) return
        //create self harm assessment
        if (!props.editForm || (!(props.editForm.data.incidentType === 'Self-Harm' || props.editForm.data.incidentType === 'Self-Harm and Harm Toward Others')))
            form.createConnectedModelId = form.data.incidentType === 'Self-Harm' || form.data.incidentType === 'Self-Harm and Harm Toward Others' ? 3 : null 
        setForm(form)
    },[form.data.incidentType])

    

    useEffect( () => {
        if (loading) return
        form.publishToThreatTeam = !!form.data.shouldFurtherReview && form.data.incidentType !== 'Self-Harm' && !!form.data.reviewBySBTMT
        setForm(form)
    },[form.data.shouldFurtherReview, form.data.reviewBySBTMT, form.data.incidentType])


    const handleSave = async (event) => {
        event.preventDefault();
        try {
            if (props.editForm)
                await new formService().update(form)
            else
                await new formService().save(form)
            props.history.push({pathname: `/assessment/${form.threatAssessmentId}` });
            context.toastSuccess('Form Saved')
        }
        catch(err){
            console.log(err)
            context.toastError('Form could not be saved')
            Sentry.captureException(err);
        }
    }

    return (
    <div id="assessmentForm">
        <form onSubmit={handleSave}>            
            <Paper className="paper">
                <h3>Form A</h3>

                <div className="highlighted-instructions">
                    Please complete any and all interviews before setting your initial assessment.
                </div>
                <div className='section'>
                    Through a preliminary review of information known, the SBTMT chair must assess whether a factual basis exists for the claim as soon as possible, but within one school day of receiving the information.
                </div>
                <div className="flex-row">                
                    <div className="flex-col">
                        <FormControl component="fieldset">
                            <RadioGroup row>
                                <FormControlLabel label="Further Review Needed" control={<Radio required />} checked={ !!form.data.shouldFurtherReview } type="radio" required onChange={() => { form.data.initialClassification = null; form.data.shouldFurtherReview=true; setForm(form) }  }/>
                                <div className="radio-help"><HelpToolTip title={<div>The chairperson will initially evaluate the report to determine whether there appears to be a factual basis for the assertions that warrant further review by the SBTMT</div> }></HelpToolTip></div>

                                <FormControlLabel label="Low Level Threat" control={<Radio required />} checked={ form.data.initialClassification === 'Low Level Threat' } type="radio" required onChange={() => {  form.data.initialClassification = 'Low Level Threat'; form.data.shouldFurtherReview=false;  setForm(form) } }/>
                                <div className="radio-help"><HelpToolTip title={<div>If the chairperson determines that the matter does not warrant review by the threat management team and it should be summarily closed, then the chairperson shall assign the case a Low level of concern</div> }></HelpToolTip></div>

                                <FormControlLabel label="Matter is Unfounded" control={<Radio required />} checked={ form.data.initialClassification === 'Unfounded' } type="radio" required onChange={() => { form.data.initialClassification = 'Unfounded'; form.data.shouldFurtherReview=false; setForm(form) } }/>
                                <div className="radio-help"><HelpToolTip title={<div>This unfounded summary disposition should only be used when it is clear and articulable that there is no basis for concern</div> }></HelpToolTip></div>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="flex-col">
                        <TextField label='Actions Taken By Chair' required multiline type="text"  inputProps={{ maxLength: 8000}}  value={form.data?.actionsTakenComments || ''} onChange={(e) => { if (!form.data) form.data = {}; form.data.actionsTakenComments = e.target.value; setForm(form)}}/>                        
                    </div>
                    <div className="flex-col help-section">
                        <HelpToolTip title={<div>These include but not limited to, interviews conducted, records reviewed, prior incidents examined, etc.</div> }></HelpToolTip>                        
                    </div>
                </div>
                { form.data.initialClassification !== 'Unfounded' ?
                <div className="flex-row">
                    <div className="flex-col">
                     <TextField label='Reason for Factual Basis Decision' required multiline  inputProps={{ maxLength: 8000}}  type="text" value={form.data?.initialClassificationComments || ''} onChange={(e) => { if (!form.data) form.data = {}; form.data.initialClassificationComments = e.target.value; setForm(form)}}/>
                    </div>
                    <div className="flex-col help-section">
                        <HelpToolTip title={<div>What information led to the case being summarily closed as unfounded or referred to the SBTMT</div> }></HelpToolTip>                        
                    </div>
                </div>
                : null }
            </Paper>

            { form.data.continue2 ?
            <section>
                <Paper className="paper">
                    <div className="flex-row ">
                        <div className="flex-col">
                        <TextField select label="Does the Claim Allege:" required value={form.data.incidentType || ''} onChange={(e) => { form.data.incidentType = e.target.value; setForm(form)}}>
                            <MenuItem value="Self-Harm">Self-Harm</MenuItem>
                            <MenuItem value="Harm Toward Others">Harm Toward Others</MenuItem>
                            <MenuItem value="Self-Harm and Harm Toward Others">Self-Harm and Harm Toward Others</MenuItem>
                            </TextField>
                        </div>
                    </div>

                    { form.data.incidentType === 'Self-Harm' || form.data.incidentType === 'Self-Harm and Harm Toward Others' ?
                    <div>
                        <div className="flex-row">
                            <div className="flex-col">
                                <TextField label='Who Was the Matter Referred to for a Self-Harm Assessment' maxLength="100" type="text" value={form.data?.selfHarmAssessmentReferredTo || ''} onChange={(e) => { form.data.selfHarmAssessmentReferredTo = e.target.value; setForm(form)}}/>
                            </div>
                            <div className="flex-col help-section">
                                <HelpToolTip title={<div>i.e., School-based counseling, district, both, other</div> }></HelpToolTip>                        
                            </div>
                        </div>

                        <div className="flex-row">
                            <div className="flex-col">
                                <TextField label='What Action(s) were Taken for Self Harm' multiline  inputProps={{ maxLength: 200}} type="text" value={form.data?.selfHarmActions|| ''} onChange={(e) => { form.data.selfHarmActions = e.target.value; setForm(form)}}/>
                            </div>
                            <div className="flex-col help-section">
                                    <HelpToolTip title={<div>i.e., counseling, therapy, Baker Act, etc.</div> }></HelpToolTip>                        
                            </div>
                        </div>
                    </div>
                    : null }
                </Paper>
            </section>
            : null }

            { form.data.continue3 ?
            <section>
            <Paper className="paper">
                <div>
                    For Claim that Involves Harm Toward Another, Does the Claim Warrant Review by the Full SBTMT?:
                </div>
                <div className="flex-row">                
                    <div className="flex-col">
                        <FormControl component="fieldset">
                            <RadioGroup row>
                                <FormControlLabel label="Review by the Full SBTMT Warranted" control={<Radio required />} checked={ !!form.data.reviewBySBTMT } type="radio" required onChange={() => { form.data.reviewBySBTMT = true; form.data.continue3=true; form.data.secondClassification = null; setForm(form) }  }/>
                                <div className="radio-help"><HelpToolTip title={<div>Indicate whether services were included, i.e., disciplinary action(s), in school assistance, etc</div> }></HelpToolTip></div>

                                <FormControlLabel label="Low Level Threat" control={<Radio required />} checked={ form.data.secondClassification === 'Low Level Threat' } type="radio" required onChange={() => { form.data.secondClassification = 'Low Level Threat'; form.data.reviewBySBTMT = false;  form.data.continue3=false; setForm(form) } }/>
                                <div className="radio-help"><HelpToolTip title={<div>If the SBTMT designates the threat as “low” include some of the risk factors that led to that decision</div> }></HelpToolTip></div>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                { form.data.secondClassification === 'Low Level Threat' ?
                    <section>
                        <div className="flex-row">
                            <div className="flex-col">
                            <TextField label='Why Designated as Low Level Threat' multiline type="text"  inputProps={{ maxLength: 200}}  value={form.data?.lowReason || ''} onChange={(e) => { form.data.lowReason = e.target.value; setForm(form)}}/>
                            </div>
                        </div>                        
                    </section>
                : null }

                <div>
                    Was the Case Referred for Services?
                </div>
                <div className="flex-row">                
                    <div className="flex-col">
                        <FormControl component="fieldset">
                            <RadioGroup row>
                                <FormControlLabel label="Yes" control={<Radio required />} checked={ !!form.data.isReferredForService } type="radio" required onChange={() => { form.data.isReferredForService = true; setForm(form) }  }/>
                                <FormControlLabel label="No" control={<Radio required />} checked={ form.data.isReferredForService === false } type="radio" required onChange={() => { form.data.isReferredForService = false; setForm(form) } }/>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                { !!form.data.isReferredForService ?
                    <div className="flex-row">
                        <div className="flex-col">
                            <TextField label='What Services?' maxLength="100" type="text" value={form.data?.referredForService || ''} onChange={(e) => {  form.data.referredForService = e.target.value; setForm(form)}}/>
                        </div>
                    </div>
                : null }
            </Paper>
            </section>
            : null }

            { form.publishToThreatTeam ?
                <div className="flex-row reviewMessage">
                    <div className="flex-col highlighted-instructions">
                        The SBTMT must meet no later than the next school day from the day the initial report was received by the Chair.
                 </div>
                </div>
            : null }

            { form.sendForReviewType ? 
            <div className="reviewMessage">
                <i className="fa fa-envelope"></i> An email will be sent for review  <HelpToolTip title={<div>Send for review to reviewers (set in User Management)</div> }></HelpToolTip> 
            </div>
            : null }

            <div>
                <Button variant="outlined" type="submit">
                    <Icon className="fa fa-save" />Save
                </Button>
            </div>
        </form>
    </div>
    )
}