import {get} from './http'

export default class threatAssessmentService {
  getAll(isOpen, year) {
    return fetch(`/api/threatAssessment/getThreatAssessments?isOpen=${+isOpen}&year=${year || ''}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getToReview(){
    return fetch(`/api/threatAssessment/list/getToReview`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  search(searchOptions) {
    return fetch('/api/threatAssessment/search', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(searchOptions),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }


  downloadSearch(searchOptions) {
    window.open(`/api/threatAssessment/search/download?startDate=${searchOptions.startDate.format('YYYY-MM-DD')}&endDate=${searchOptions.endDate.format('YYYY-MM-DD')}`)
  }

  getSimilar(threatAssessment) {
    return fetch(`/api/threatAssessment/getSimilar?assessedFirstName=${threatAssessment.assessedFirstName}&assessedLastName=${threatAssessment.assessedLastName}&dateOfBirth=${threatAssessment.dateOfBirth}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  get(threatAssessmentId) {
     return fetch(`/api/threatAssessment/getThreatAssessment?threatAssessmentId=${threatAssessmentId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
     .then(response => { 
      if (!response.ok) {
        if (response.status === 401)
          return { error: 'access'} 
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getYearCount(isOpen){
    return fetch(`/api/threatAssessment/getThreatAssessmentYearCount?isOpen=${+isOpen}`, { method: 'GET', mode: 'cors', credentials: 'include' })
     .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getAssessmentTypes(institutionBuildingId){
    return fetch(`/api/threatAssessment/getAssessmentTypes?institutionBuildingId=${institutionBuildingId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then( (response) => {
      if (!response.ok)
        throw Error(response.statusText);

      return response.json();
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getAssessmentModels(institutionBuildingId){
    return fetch(`/api/threatAssessment/getAssessmentModels?institutionBuildingId=${institutionBuildingId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then( (response) => {
      if (!response.ok)
        throw Error(response.statusText);

      return response.json();
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getAssessmentModelByAssessmentId(assessmentId){
    return fetch(`/api/threatAssessment/getAssessmentModelByAssessmentId?assessmentId=${assessmentId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then( (response) => {
      if (!response.ok)
        throw Error(response.statusText);

      return response.json();
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  create(threatAssessment) {
    return fetch('/api/threatAssessment/createThreatAssessment', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(threatAssessment),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  update(threatAssessment) {
    return fetch('/api/threatAssessment/updateThreatAssessment', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(threatAssessment),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  edit(threatAssessment) {
    return fetch('/api/threatAssessment/editThreatAssessment', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(threatAssessment),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  resolve(threatAssessment){
    return fetch('/api/threatAssessment/resolveThreatAssessment', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(threatAssessment),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  delete(threatAssessment){
    return fetch('/api/threatAssessment/deleteThreatAssessment', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(threatAssessment),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok;
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })  
  }

  saveInterview(interview) {
    return fetch('/api/threatAssessment/interview/saveThreatAssessmentInterview', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(interview),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok;  
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  updateInterview(interview) {
    return fetch('/api/threatAssessment/interview/updateThreatAssessmentInterview', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(interview),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok;  
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getQuestions(institutionBuildingId, questionType ) {
    return get('/api/threatAssessment/interview/getInterviewQuestions', { institutionBuildingId, questionType })    
  }

  getThreatAssessmentInterview(threatAssessmentInterviewId){
    return fetch(`/api/threatAssessment/interview/getThreatAssessmentInterview?threatAssessmentInterviewId=${threatAssessmentInterviewId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
     if (!response.ok) {
       throw Error(response.statusText);
     }
     return response.json(); 
   })
   .catch( (error) => {
     console.error(error);
     throw Error(error);
   })
 }

  

  download(threatAssessmentId){
    window.open(`/api/threatAssessment/downloadThreatAssessment?threatAssessmentId=${threatAssessmentId}`)
  }

  share(threatAssessmentId, userId) {
    return fetch(`/api/threatAssessment/shareThreatAssessment`, 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify({threatAssessmentId, userId}),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok;  
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }


  notify(threatAssessmentId, email) {
    return fetch(`/api/threatAssessment/notifyThreatAssessment`, 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify({threatAssessmentId, email}),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok;  
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  resendRequestReview(threatAssessmentId) {
    return fetch(`/api/threatAssessment/resendRequestReview`, 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify({threatAssessmentId}),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok;  
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }


  getAssetNames(threatAssessmentId, assetType){
    return fetch(`/api/threatAssessment/getAssetNames?threatAssessmentId=${threatAssessmentId}&assetType=${assetType}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  uploadFile(threatAssessmentId, file, assetType){
    return fetch(`/api/threatAssessment/uploadFile?threatAssessmentId=${threatAssessmentId}&assetType=${assetType}`, {
      method: 'POST',
      mode: 'cors', 
      credentials: 'include',
      body: file,
    }).then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response
    }) 
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  downloadAsset(assetId){
    window.open(`/api/threatAssessment/downloadAsset?assetId=${assetId}`)
  }

  deleteAsset(assetId){
    return fetch('/api/threatAssessment/deleteAsset', {
      method: 'POST',
      mode: 'cors', 
      credentials: 'include',
      json: true,
      body: JSON.stringify({ assetId }),
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    }).then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response
    }) 
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  reviewApprove(threatAssessmentId, comment, reviewType) {
    return fetch('/api/threatAssessment/review/approve', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify({threatAssessmentId, comment, reviewType}),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok;  
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  reviewReject(threatAssessmentId, comment, reviewType) {
    return fetch('/api/threatAssessment/review/reject', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify({threatAssessmentId, comment, reviewType}),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok;  
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

}