import React, { useEffect, useContext, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Button, TextField, Checkbox, MenuItem, FormControl, FormGroup, RadioGroup, Radio, FormControlLabel} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import AppContext from '../../../context/app'
import OtherCheckbox from '../../../components/OtherCheckbox';
import threatAssessmentService from '../../../services/threatAssessment';
import Loader from '../../../components/Loader';


export default function Resolve(props) {
  const context = useContext(AppContext)
  const { threatAssessmentId } = useParams()
  const [assessment, setAssessment] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    null
  );
  const [resolution, setResolution] = useState({})
  const [assessmentModel, setAssessmentModel] = useState(null)
  const [loading, setLoading] = useState(true)
  
  useEffect( async () => {
    window.scroll(0,0)

    try {
      //load all data needed
      let threatAssessment = await new threatAssessmentService().get(threatAssessmentId)
      if (threatAssessment && threatAssessment.error){
        setLoading(false)
        return
      }

      if (threatAssessment.resolutionFollowUpDate)
        threatAssessment.resolutionFollowUpDate = moment(threatAssessment.resolutionFollowUpDate)
      if (threatAssessment.resolution)
        setResolution(threatAssessment.resolution)
      setAssessment(threatAssessment)

      let loadedAssessmentModel = await new threatAssessmentService().getAssessmentModelByAssessmentId(threatAssessment.threatAssessmentId)
      setAssessmentModel(loadedAssessmentModel)
      
      setLoading(false)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const handleSave = async (event) => {
    event.preventDefault();
    try {
        let resolutionUpdate = { threatAssessmentId, resolutionFollowUpDate: resolution.resolutionFollowUpDate, resolutionNotes: resolution.resolutionNotes, data: resolution.data };
        await new threatAssessmentService().resolve(resolutionUpdate)
        context.toastSuccess('Notes saved')
    }
    catch(err){
      console.log(err)
      context.toastError('Notes could not save')
      Sentry.captureException(err);
    }
  }

    if(loading && !assessment)
      return (<Loader/>)

    if (assessment && assessmentModel?.interviewTypes.length && !assessment.interviewers.length && assessment.isOpen)
      return (
        <Paper className="paper">
          Please complete interviews before creating an action plan
        </Paper>
      )

    if (assessment)
      return ( 
        <div id='threatAssessmentResolve'>
          <form onSubmit={handleSave}>
            <Paper className="paper">
                <h2>Case Resolution or Safety Plan</h2>
                <div className='flex-row section'>
                    {assessment.resolutionClassification ?
                        <div className='flex-col'>
                            <label>Classification:</label> {assessment.resolutionClassification}
                        </div>
                    : null }
                </div>
                <div className='flex-row section'>
                    <div className='flex-col resolution-notes-section'>
                            <TextField multiline maxRows={10} inputProps={{ maxLength: 12000}}   maxLength="12000" label="Case Resolution or Safety Plan" value={resolution?.resolutionNotes || ''}  onChange={ (e) => { resolution.resolutionNotes = e.target.value; setResolution({...resolution}) } }/> 

                        { resolution.resolutionFollowUpDate ?
                        <div className='section'>
                            Follow up { moment(resoultion.resolutionFollowUpDate).format('MM/DD/YYYY') }
                        </div>
                        : null }
                    </div>
                </div>

                <div className="section">
                    <Button variant="outlined" type="submit">
                      <Icon className="fa fa-save" />Save
                    </Button>
                  </div>
            </Paper>
          </form>
        </div>
      )
}