import React, { useEffect, useContext, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Button, TextField} from '@mui/material';
import AppContext from '../../../context/app'
import threatAssessmentService from '../../../services/threatAssessment';
import Loader from '../../../components/Loader';


export default function Notes(props) {
  const context = useContext(AppContext)
  const { threatAssessmentId } = useParams()
  const [assessment, setAssessment] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    null
  );
  const [resolution, setResolution] = useState({})
  const [loading, setLoading] = useState(true)
  
  useEffect( async () => {
    window.scroll(0,0)

    try {
      //load all data needed
      let threatAssessment = await new threatAssessmentService().get(threatAssessmentId)
      if (threatAssessment && threatAssessment.error){
        setLoading(false)
        return
      }
      if (threatAssessment.resolution)
        setResolution(threatAssessment.resolution)
      setAssessment(threatAssessment)

      setLoading(false)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const handleUpdateResolutionNotes = async (event) => {
    event.preventDefault();
    try {
        let updatedResolution = { threatAssessmentId, resolutionNotes: resolution.resolutionNotes };
        await new threatAssessmentService().resolve(updatedResolution)
        context.toastSuccess('Notes saved')
    }
    catch(err){
      console.log(err)
      context.toastError('Notes could not save')
      Sentry.captureException(err);
    }
  }

    if(loading)
      return (<Loader/>)

    if (assessment)
      return ( 
        <div id='threatAssessmentNotes'>
          <Paper className="paper">
              <div className='flex-row section'>
                  <div className='flex-col resolution-notes-section'>
                    <TextField multiline maxRows={10}  inputProps={{ maxLength: 12000}}  maxLength="12000" label="Notes" value={resolution.resolutionNotes || ''}  onChange={ (e) => { resolution.resolutionNotes = e.target.value; setResolution({...resolution}) } }/> 
                  </div>
              </div>
              <div className="section">
                  <Button variant="outlined" onClick={handleUpdateResolutionNotes}>
                      <Icon className="fa fa-save" />Save
                  </Button>
              </div>
          </Paper>
        </div>
      )
}